export default function (id) {
  let window = id ? `patio.local.${id}` : 'patio.local.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    // title: 'Local' + (id ? ` #${id}` : ''),
    title: 'Gerenciamento de Locais e Vagas',
    width: '900',
    height: '600',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id
    }
  }, () => import('../components/window/PatioLocal.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, storage) => {
          console.log('Criou local')
          this.load?.()
        }
      })
      console.log(wid)
    }) // return wid
}

export default {
    methods: {
        montaFiltros (filters = {}) {
            const filtros = filters || {}
            let result = ''
            for (const key in filtros) {
                if (!Object.prototype.hasOwnProperty.call(filtros, key)) continue
                const value = filtros[key]
                if (value === null) continue
                result += `&${key}=${value}`
            }
            return result
        }
    }
}
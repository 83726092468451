<template>
  <div class="m-t-md w-full flex" style="display: flex; align-items: center;">
    <u-btn
        class="m-r-sm"
        icon="chevron-left"
        icon-type="fa"
        icon-style="light"
        flat
        no-caps
        size="xs"
        @click="load(PREVIOUS)"
        :disable="blockButton[PREVIOUS]"
    />
    <erp-s-field style="max-width: 40px" class="m-t-n-sm m-r-xs">
      <erp-input simple-border v-model="currentPageData" ref="pageInput" @keyup.enter.prevent="load(null)" type="number" size="sm" />
    </erp-s-field>de {{totalPages}}
    <u-btn
        class="m-l-sm"
        icon="chevron-right"
        icon-type="fa"
        icon-style="light"
        flat
        no-caps
        size="xs"
        @click="load(NEXT)"
        :disable="blockButton[NEXT]"
    />
  </div>
</template>

<script>
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'

const PREVIOUS = 0
const NEXT = 1

export default {
  name: "SimplePaginator",
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: () => 1
    },
    totalResults: {
      type: Number,
      required: false,
      default: () => 0
    },
    limit: {
      type: Number,
      required: false,
      default: () => 1
    }
  },
  components: {
    ErpInput,
    ErpSField
  },
  data () {
    return {
      currentPageData: 1,
      PREVIOUS,
      NEXT,
      blockButton: {
        [PREVIOUS]: true,
        [NEXT]: false
      }
    }
  },
  computed: {
    totalPages () {
      let {totalResults: total, limit} = this
      limit = limit < 1 ? 1 : limit
      return Math.ceil(total / limit)
    },
    isLimitPage () {
      return this.currentPageData <= 1 || this.currentPageData >= this.totalPages
    }
  },
  created () {
    this.currentPageData = this.currentPage
  },
  methods: {
    load (direction = null) {
      console.log('loading')
      if (direction && this.blockButton[direction]) return
      this.listener(direction)

      Object.keys(this.blockButton).forEach(key => { this.blockButton[key] = false })
      if (this.isLimitPage) {
        this.blockButton[direction] = true
      }
      this.$emit('changePage', this.currentPageData)
    },
    listener (direction) {
      const fn = {
        [PREVIOUS]: () => {
          --this.currentPageData
          this.currentPageData = this.currentPageData < 1 ? 1 : this.currentPageData
        },
        [NEXT]: () => {
          ++this.currentPageData
          this.currentPageData = this.currentPageData > this.totalPages ? this.totalPages : this.currentPageData
        },
        [null]: () => {
          if (this.currentPageData > this.totalPages) {
            this.currentPageData = this.totalPages
          } else if (this.currentPageData < 1) {
            this.currentPageData = 1
          }
        }
      }
      fn[direction]?.()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="erp-row-col-md">

        <div class="col" style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Código:"
              label-width="90px"
          >
            <erp-input simple-border v-model="patio.id" readonly />
          </erp-s-field>
        </div>

        <div class="col" style="max-width: 200px">
          <tipo-patio-select label="Tipo:" required field-view="lr" v-model="patio.tipo" autofocus />
        </div>

        <div class="col" style="max-width: 110px">
          <sigla-patio-input v-model="patio.sigla"></sigla-patio-input>
        </div>

        <e-col style="max-width: 110px">
          <uf-select field-view="lr" label="UF:" v-model="patio.uf" />
        </e-col>
        <e-col>
          <cidade-select field-view="lr" :uf="patio.uf" label="Cidade:" v-model="patio.cidade" />
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome do Pátio:"
              label-width="90px"
          >
            <erp-input simple-border v-model="patio.nome" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="CEP:"
          >
            <erp-input simple-border v-model="patio.cep" v-mask="'#####-###'" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Endereço:"
          >
            <erp-input simple-border v-model="patio.endereco" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="erp-row-col-md">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="Número:"
              label-width="90px"
          >
            <erp-input simple-border v-model="patio.numero" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Complemento:"
          >
            <erp-input simple-border v-model="patio.complemento" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Bairro:"
          >
            <erp-input simple-border v-model="patio.bairro" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="Status"
          >
            <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]"
                        v-model="patio.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-xs" mr>
        <e-col style="max-width: 260px">
          <erp-s-field
              view="lr"
              label-width="90px"
              tag="div"
          >
            <erp-checkbox class="flex items-center" v-model="patio.armazenamento">
              <label class="no-select font-bold font-11">
                <span class="m-l-xs">Armazenamento de Bens</span>
              </label>
            </erp-checkbox>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              tag="div"
          >
            <erp-checkbox class="flex items-center" v-model="patio.leiloes">
              <label class="no-select font-bold font-11">
                <span class="m-l-xs">Realização de Leilões</span>
              </label>
            </erp-checkbox>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="locais-vagas">Locais e Vagas</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper">
        <!--Sem regras de acesso. Consulte o administrador--> <!-- FAKE. @TODO -->
        <div class="wrapper-md">
          <div>
            <widgets :maxSlots="12" :adjusts="[{'screen': 0, 'slots': 12}, {'screen': 0, 'slots': 4}]" ref="widgets">
              <widget-item :wslot="4" :hslot="3">
                <widget-patio-stats
                    title="Vagas"
                    :count="[{title: null, value: (patio.stats || {}).total || 0}]"
                >
                  <slot slot="link"><a>Visualizar</a></slot>
                </widget-patio-stats>
              </widget-item>

              <widget-item :wslot="4" :hslot="3">
                <widget-patio-stats
                    title="Ocupadas"
                    style="background-color: #FFEBEB;"
                    :count="[{title: null, value: (patio.stats || {}).ocupadas || 0}]"
                >
                  <slot slot="link"><a>Visualizar</a></slot>
                </widget-patio-stats>
              </widget-item>

              <widget-item :wslot="4" :hslot="3">
                <widget-patio-stats
                    title="Disponíveis"
                    style="background-color: #F1FFF1"
                    :count="[{title: null, value: (patio.stats || {}).disponiveis || 0}]"
                >
                  <slot slot="link"><a>Visualizar</a></slot>
                </widget-patio-stats>
              </widget-item>
            </widgets>
          </div>
        </div>
        <table-responsaveis-patio
            :lista="patio.responsaveis"
            @selecionaResponsavel="SearchResponsavelPatio"
            :enable-add="!!patio.id"
        ></table-responsaveis-patio>
      </div>
      <div v-show="tabActive === 'locais-vagas'" class="wrapper">
        <e-row>
          <e-col style="max-width: 500px" class="m-t-xs">
            <span style="font-weight: bold">Filtros:</span>
          </e-col>
          <e-col style="max-width: 200px">
            <tipo-vaga-select label="Tipo de vaga:" field-view="lr" v-model="filtros.tipoVaga" @input="pesquisar"></tipo-vaga-select>
          </e-col>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="Pesquisar:"
                label-width="90px"
            >
              <erp-input simple-border v-model="filtros.search" @keyup="pesquisar" />
            </erp-s-field>
          </e-col>
        </e-row>
        <table-locais-patio
            :lista="patio.locais"
            @adicionaLocal="windowPatioLocal"
            @open="openLocal"
            :loading="loadingLocais"
            :enable-add="!!patio.id"
        ></table-locais-patio>
        <simple-paginator
            :current-page="serverPagination.page"
            :total-results="serverPagination.total"
            :limit="serverPagination.rowsPerPage"
            @changePage="changePage"
        ></simple-paginator>
      </div>
    </window-body>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Pátio registrado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpSelect,
  ErpCheckbox
  // HelperInputBtn,
  // ErpBox
  // ErpLabel
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {
  find,
  newPatio,
  updatePatio,
  adicionarResponsavelPatio
} from '../../../../domain/patio/services/index'
import TipoPatioSelect from '../../../cadastro/components/tipospatio/include/TipoPatioSelect'
import UfSelect from '../../../remocao/components/include/UfSelect'
import CidadeSelect from '../../../remocao/components/include/CidadeSelect'
import ECol from '../../../layout/components/Col'
import ERow from '../../../layout/components/Row'
import TableResponsaveisPatio from "../include/tables/TableResponsaveisPatio";
import SearchResponsavelPatio from "./search/SearchResponsavelPatio";
import WidgetPatioStats from "../../../remocao/components/WidgetPatioStats";
import Widgets from "../../../layout/widget/Widgets";
import WidgetItem from "../../../layout/widget/WidgetItem";
import TableLocaisPatio from "../include/tables/TableLocaisPatio";
import windowPatioLocal from '../../windows/patio-local'
import TipoVagaSelect from "../include/TipoVagaSelect";
import {debounce} from "uloc-vue";
import {listLocaisPatio} from "@/domain/patio/services/patio-local";
import MontaFiltrosMixins from "../../../../mixins/MontaFiltrosMixins";
import SimplePaginator from "../include/SimplePaginator";
import SiglaPatioInput from "../include/SiglaPatioInput";

let mockPatio = {
  id: null,
  nome: null,
  sigla: null,
  tipo: null,
  uf: null,
  cidade: null,
  cep: null,
  numero: null,
  complemento: null,
  bairro: null,
  endereco: null,
  armazenamento: null,
  leiloes: null,
  active: true
}

export default {
  name: 'PatioWindow',
  mixins: [MontaFiltrosMixins],
  props: ['id'],
  components: {
    SiglaPatioInput,
    SimplePaginator,
    TipoVagaSelect,
    TableLocaisPatio,
    WidgetItem,
    WidgetPatioStats,
    Widgets,
    TableResponsaveisPatio,
    ERow,
    ECol,
    CidadeSelect,
    UfSelect,
    TipoPatioSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpSelect,
    ErpCheckbox
    // HelperInputBtn,
    // ErpLabel
  },
  data () {
    return {
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      patio: JSON.parse(JSON.stringify(mockPatio)),
      filtros: {
        tipoVaga: null,
        search: null
      },
      serverPagination: {
        page: 1,
        rowsNumber: 0, // specifying this determines pagination is server-side
        rowsPerPage: 20
      },
      locaisFiltrados: null,
      loadingLocais: false
    }
  },
  mounted () {
    this.id && this.load()
    this.id && this.loadLocais(this.serverPagination.rowsPerPage, this.serverPagination.page, this.montaFiltros(this.filtros))
  },
  methods: {
    load () {
      return find(this.id || this.patio.id)
          .then(response => {
            let data = response.data

            if (data.tipo && data.tipo.id) {
              data.tipo = data.tipo.id
            }

            if (data.cidade && data.cidadeId) {
              data.cidade = data.cidadeId
            }

            data.responsaveis = data.responsaveis.map(r => r.pessoa)
            data.stats = data.stats || {}

            this.patio = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar o patio, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.patio))

      if (data.cidade && data.cidade.id) {
        data.cidadeId = data.cidade.id
        data.cidade = data.cidade.nome
      }
      if (data.uf && data.uf.id) { // Not work
        data.ufId = data.uf.id
        data.uf = data.uf.nome
      }
      data.cep = data.cep?.replace(/\D/g, '')

      // data.extra = JSON.stringify(data.extra)

      this.status = 'Processando dados do patio'
      let method = data.id ? updatePatio(data.id, data) : newPatio(data)
      method
          .then(({data}) => {
            this.patio.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.load()
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
                this.$refs.timer.start()
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.alertApiError(error)
              // this.error = true
            })
          })
      .finally(() => {
        this.loading = false
      })
    },
    adicionaResponsavelPatio ({id}) {
      this.$uloc.notify({color: 'warning', message: `Adicionando responsável, aguarde...`})
      adicionarResponsavelPatio(this.id || this.patio.id, id)
          .then(result => {
            this.$uloc.notify({color: 'positive', message: 'Responsável adicionado ao pátio', icon: 'check'})
            this.load()
          })
          .catch(error => {
            console.log(error)
            this.alertApiError(error)
          })
    },
    confirmaAdicionarResponsavel (data) {
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza de que deseja adicionar "${data.name}" como responsável por este pátio?`,
        cancel: true,
        color: 'negative'
      })
          .then(() => {
            this.adicionaResponsavelPatio(data)
          })
          .catch(() => {})
    },
    openLocal (data) {
      this.windowPatioLocal(data.id)
    },
    loadLocais (limit = 1000, page = 1, filtros = '') {
      this.loadingLocais = true
      this.locaisFiltrados = []
      listLocaisPatio(this.id, limit, page, filtros)
          .then(response => {
            this.patio.locais = response.data.result
            this.serverPagination.total = +response.data.total
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loadingLocais = false
          })
    },
    pesquisar: debounce(function () {
      const {rowsPerPage, page} = this.serverPagination
      this.loadLocais(rowsPerPage, page, this.montaFiltros(this.filtros))
    }, 500),
    changePage (newPage) {
      this.serverPagination.page = newPage
      this.pesquisar()
    },
    SearchResponsavelPatio,
    windowPatioLocal
  }
}
</script>

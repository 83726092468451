<template>
  <div>
    <div class="font-sb font-12 m-b-sm">Responsáveis legais</div>
    <div class="col-grow flex justify-between h-full">
      <e-window-table
          class="col-grow h-full h-limited"
          :columns="['ID', 'Nome', 'Opções']"
      >
        <e-tr v-for="(data, index) in lista" :key="index">
          <e-td><span>{{ String(data.id).padStart(4, '0') }}</span></e-td>
          <e-td>{{ data.name }}</e-td>
          <e-td>
            <a><i class="erp-icon search min"></i> </a>
            <u-icon name="times" type="fa" icon-style="regular" class="text-negative text-md m-l cursor-pointer m-b-xs" @click.native="remove(data)"></u-icon>
          </e-td>
        </e-tr>
        <e-tr v-if="!lista || !lista.length">
          <e-td style="text-align: left !important;" colspan="100%">
            Nenhum registro
          </e-td>
        </e-tr>
      </e-window-table>
    </div>
    <div class="flex justify-end">
      <u-btn class="m-t-sm" size="sm" @click="selecionaResponsavel" v-if="enableAdd">Adicionar responsável</u-btn>
    </div>
  </div>
</template>

<script>
import {
  EWindowTable,
  ETd,
  ETr
} from 'uloc-vue-plugin-erp'
import {TIPO_PESSOA_FISICA} from "../../../../../domain/pessoa/helpers/Status";

export default {
  name: "TableResponsaveisPatio",
  props: {
    lista: {
      type: Array,
      required: true,
      default: () => []
    },
    enableAdd: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      TIPO_PESSOA_FISICA
    }
  },
  components: {
    EWindowTable,
    ETd,
    ETr
  },
  methods: {
    remove (data) {
      console.log(data)
      this.$emit('remove', data)
    },
    selecionaResponsavel () {
      this.$emit('selecionaResponsavel')
    }
  }
}
</script>

<style scoped>
.h-limited {
  max-height: 200px;
  overflow: auto;
}
</style>

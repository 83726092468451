import {listPossiveisResponsaveis as list} from "../../../../../domain/patio/services";

const search = function () {
    console.log('click window search responsável...')
    let window = 'windowSearch'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Adicionar responsável por este pátio',
        width: '800',
        height: '600',
        minHeight: '500',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden bg-white',
        props: {
            columns: [
                // {label: 'ID', value: 'id'},
                {label: 'Login', value: 'username'},
                {label: 'Nome', value: 'personName'},
                {label: 'E-mail', value: 'email'}
            ],
            fetchData: (terms, filters, done) => {
                console.log('Buscando dados: ', terms, filters, done)
                let rowsPerPage = 100
                list(rowsPerPage, filters.page, `&sortBy=id&descending=desc`)
                    .then(({data}) => {
                        console.log(data)
                        let filter = function (terms, {field, list}) {
                            const token = terms.toLowerCase()
                            return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
                        }

                        const tmp = data.result.map(item => {
                            return {
                                ...item,
                                personId: item.person.id,
                                personName: item.person.name
                            }
                        })
                            .filter(item => !['slroot', 'admin'].includes(item.username)) // TODO: TEMPORÁRIO; TRATAR NO BACKEND

                        let filteredData = filter(terms, {field: 'personName', list: tmp})

                        done(filteredData, {
                            page: filters.page,
                            rowsNumber: data.total, // response.totalRows,
                            rowsPerPage: rowsPerPage
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }, () => import('../../../../layout/window/WindowSearch.vue'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                selected: (wid, val) => {
                    console.log('Selecionado com sucesso: ', val)
                    this.__emit && this.__emit(val.person.id)
                    this.confirmaAdicionarResponsavel?.(val.person)
                }
            })
            console.log(wid)
        }) // return wid
}

export default search

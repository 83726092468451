import http from "../../../utils/services/http";

export const newLocalPatio = data => {
    let url = '/api/patios/new-local'
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateLocalPatio = (localId, data) => {
    let url = `/api/patios/local/${localId}`
    return http.patch(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTiposVagas = (limit, page, filtros) => {
    let url = '/api/tiposVagaPatio?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (patioLocalId) => {
    let url = `/api/patios/local/${patioLocalId}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

// TODO: mover para o arquivo patio.js
export const listLocaisPatio = (patioId, limit, page, filtros) => {
    let url = `/api/patios/${patioId}/locais?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listaVagasPatioLocal = (patioLocalId, limit, page, filtros) => {
    let url = `/api/patios/local/${patioLocalId}/vagas?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}